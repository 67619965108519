.pn-list {
  padding-bottom: 20px;
}

.pn-list .btn-link {
  background-color: white;
  color: #0c0c0c;
  border-color: #d9d9d9;
}

pn-list td {
  padding-left: 10px;
}
.pn-list a {
  color: #4550e0;
}

.pn-list-button {
  color: #0c0c0c;
}

.modal-dialog {
  max-width: 95vw !important;
}

.modal-content {
  width: fit-content !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: inherit;
  max-height: 90vh;
  overflow: scroll;
  width: max-content !important;
}

.modal-content .table {
  max-width: 100%;
  display: block;
  overflow-x: auto;
}
